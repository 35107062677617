/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

import React from "react";

import "./App.css";
import MapWithSearch from "./MapWithSearch";

/**
 * Sample app that helps users locate a college on the map, with place info such
 * as ratings, photos, and reviews displayed on the side.
 */
const App = () => {
  return (
    <div>
      <h1>Google Map with Search</h1>
      <MapWithSearch />
    </div>
  );
};

export default App;
