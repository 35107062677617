import React, { useEffect, useRef, useState } from "react";

import { Loader, LoaderStatus } from "@googlemaps/js-api-loader";
import { GoogleMap, Autocomplete } from "react-google-autocomplete";

const API_KEY = process.env.REACT_APP_MAPS_API_KEY;
const DEFAULT_CENTER = { lat: 11.51793501431579, lng: 106.87075594702146 }; //{ lat: 106.90615846322231, lng: 11.519405626491585 }; //
const DEFAULT_ZOOM = 15;
const DEFAULT_ZOOM_WITH_LOCATION = 16;

const KML_URL =
  "https://firebasestorage.googleapis.com/v0/b/vero-map.appspot.com/o/dongxoai_tongthe.kml?alt=media&token=ef278154-eb4c-47be-9606-aa1a5f50756d";
// Transforming the data into GeoJSON format
let geoJSON = null; // Initialize GeoJSON as null or an empty object/array

const MapWithSearch = () => {
  const [apiKey, setApiKey] = useState(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE3MDAzODg5NDEsImV4cCI6MTcwMDM4OTg0MX0.FOJo0Hhan7S98f8zRKHexYSLNNTnTODV39125uDoSkc"
  ); // State to store API key
  const mapContainerRef = useRef(null);

  const fetchThuaDat = async (latitude, longitude, token) => {
    const baseUrl = "https://gis.binhphuoc.gov.vn:82/rest/thua-dat";
    const fields = "soTo,soThua,shape";
    const limit = 10000000;
    const fGeo = "geojson";
    const maPhuongXa = "25204";
    const maQuanHuyen = "689";

    const bbox = {
      spatialReference: { wkid: 4326 },
      xmax: longitude + 0.01, // Adjust the longitude range as needed
      xmin: longitude - 0.01,
      ymax: latitude + 0.01, // Adjust the latitude range as needed
      ymin: latitude - 0.01,
    };

    const queryParams = {
      fields,
      limit,
      fGeo,
      bbox: JSON.stringify(bbox),
      outSR: JSON.stringify({ wkid: 4326 }),
      /*     filter: [
      `maPhuongXa||$eq||${maPhuongXa}`,
      `maQuanHuyen||$eq||${maQuanHuyen}`,
    ], */
    };

    const queryString = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join("&");

    const apiUrl = `${baseUrl}?${queryString}`;

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`, // Add bearer token to the headers
        },
      });

      const data = await response.json();

      if (data) {
        // Assuming yourData contains the GeoJSON-like objects
        const geoJSONFeatures = data.map((item) => {
          return {
            type: "Feature",
            geometry: {
              type: item.shape.type,
              coordinates: item.shape.coordinates,
            },
            properties: {
              objectId: item.objectId,
              soTo: item.soTo,
              soThua: item.soThua,
              // Add more properties if needed
            },
          };
        });

        geoJSON = {
          type: "FeatureCollection",
          features: geoJSONFeatures,
        };
      }
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };
  const handleSearchByCoordinates = () => {
    const input = document.getElementById("lat-lng-input").value;
    const [lat, lng] = input.split(",");
    const location = new window.google.maps.LatLng(
      parseFloat(lat),
      parseFloat(lng)
    );
  };

  useEffect(() => {
    const loader = new Loader({
      apiKey: API_KEY, // Replace with your API key
      version: "weekly",
      libraries: ["places"],
    });

    loader.load().then(() => {
      const map = new window.google.maps.Map(mapContainerRef.current, {
        center: DEFAULT_CENTER, // Set initial center
        zoom: DEFAULT_ZOOM,
        scrollwheel: true, // Enable scroll-based zoom
      });

      // Additional map configurations or markers can be added here
      // Load KML layer
      /*  const kmlLayer = new window.google.maps.KmlLayer({
        url: KML_URL,
        map: map,
      }); */

      const geojsonLayer = new window.google.maps.Data({ map: map });
      geojsonLayer.addGeoJson(geoJSON);
      // Inside your useEffect after adding GeoJSON to the map
      const infoWindow = new window.google.maps.InfoWindow();
      // Inside your useEffect after adding GeoJSON to the map
      geojsonLayer.addListener("click", (event) => {
        const clickedFeature = event.feature;
        // Access any available properties or keys directly for exploration
        const properties = Object.keys(clickedFeature);
        //console.log("Clicked Feature Properties:", properties);
        // Access properties of the clicked feature
        //const properties = clickedFeature.getProperty("h");
        if (properties) {
          // Assuming properties is an object with keys representing the data
          // Display information in the console or in your UI
          console.log("Clicked Feature Properties:", clickedFeature);
          const contentString = JSON.stringify(clickedFeature["h"], null, 2);

          infoWindow.setContent(contentString);
          infoWindow.setPosition(event.latLng);
          infoWindow.open(map);

          // You might want to update the state here to display the information in your React component
          // Example: setInfo(properties);
        } else {
          console.log(
            "No properties found for the clicked feature.",
            clickedFeature
          );
        }
      });

      //
      if (map) {
        // Inside the 'center_changed' event listener block
        map.addListener("center_changed", () => {
          const newCenter = map.getCenter();
          const latitude = newCenter.lat();
          const longitude = newCenter.lng();

          //console.log("Map center changed:", newCenter);

          // Call fetchThuaDat to get new geojson data
          const updatedApiKey = apiKey; // Capture the latest apiKey value
          fetchThuaDat(latitude, longitude, updatedApiKey)
            .then((data) => {
              //console.log("New Thua dat data:", data);
              // Assuming your fetchThuaDat function correctly formats the fetched data into GeoJSON-like objects

              if (data) {
                const geoJSONFeatures = data.map((item) => {
                  return {
                    type: "Feature",
                    geometry: {
                      type: item.shape.type,
                      coordinates: item.shape.coordinates,
                    },
                    properties: {
                      objectId: item.objectId,
                      soTo: item.soTo,
                      soThua: item.soThua,
                      // Add more properties if needed
                    },
                  };
                });

                const newGeoJSON = {
                  type: "FeatureCollection",
                  features: geoJSONFeatures,
                };
                if (!geoJSON) {
                  geoJSON = newGeoJSON; // Set new GeoJSON if none exists
                } else {
                  // Merge the features from existing and new GeoJSON
                  geoJSON.features = [
                    ...geoJSON.features,
                    ...newGeoJSON.features,
                  ];
                }

                /* // Clear previous GeoJSON data
                geojsonLayer.forEach((feature) => {
                  geojsonLayer.remove(feature);
                }); */

                // Add combined GeoJSON data to the map
                geojsonLayer.addGeoJson(geoJSON);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        });
      }
      // Listen to place changes from autocomplete
      const autocompleteInput = document.getElementById("autocomplete-input");
      const autocomplete = new window.google.maps.places.Autocomplete(
        autocompleteInput
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          map.panTo(place.geometry.location);
          map.setZoom(DEFAULT_ZOOM_WITH_LOCATION); // Adjust zoom level as needed
        }
      });
    });
  }, [apiKey]);

  return (
    <div>
      <input
        id="autocomplete-input"
        type="text"
        placeholder="Search for a place..."
      />
      <input
        id="lat-lng-input"
        type="text"
        placeholder="Enter Lat,Lng (e.g., 40.7128,-74.0060)"
      />
      <button onClick={handleSearchByCoordinates}>Search by Lat,Lng</button>
      <input
        type="text"
        placeholder="Enter API Key"
        value={apiKey}
        onChange={(e) => {
          setApiKey(e.target.value);
          console.log(e.target.value);
        }}
      />
      <div
        ref={mapContainerRef}
        style={{ width: "100%", height: "80vh", border: "1px solid #ccc" }}
      ></div>
    </div>
  );
};

export default MapWithSearch;
